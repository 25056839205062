import React, { useState, useEffect } from "react";
import classes from './S.module.css';
import {
  Table,
  Row,
  Space,
  DatePicker,
  Select,
  Tooltip,
  Typography,
  Spin,
  Pagination,
  Button,
  Checkbox
} from "antd";
import ModalCompo from "../../../design/ModalCompo";
import DeleteIcon from "../../../assets/Remove_Icon.svg";
import ApproveIcon from "../../../assets/ApproveIcon.svg";
import RejectIcon from "../../../assets/RejectIcon.svg";
import ApprovedIcon from "../../../assets/ApprovedIcon.svg";
import UserIcon from "../../../assets/UserIcon.svg";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../components/Button/Button";
import moment from "moment";
import { useLocation } from "react-router";
import Api from "../../../services/Api";
import { useStore } from "./store";
import SearchCompo from "../../../design/SearchCompo";
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import viewClass from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import {CaretUpOutlined, CaretDownOutlined}  from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;

const CommonComponent = () => {
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [approveParams, setApproveParams] = useState({});
  const [rejectParams, setRejectParams] = useState({});
  const[selectedUser, setSelectedUser]=useState();
  const {width}=useWindowDimensions();
  const { setFilterValues,filteredData } = useProjecteStore((state) => state);
  const [filteredDeptValue,setFilteredDeptValue]=useState([]);
  const [filteredManagerValue,setFilteredManagerValue]=useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); 
  const [sortTable, setSortTable] =useState(null);

  const dateFormat = "YYYY-MM-DD"

  const [tablePagniation, setTablePagination] = useState({
    pagination: {
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
    },
  });
  const location = useLocation();

  useEffect(()=>{
    setStartDate("");
    setEndDate("");
    setStatusFilter("");
    setSearch('')
    setFilteredDeptValue([]);
    setFilteredManagerValue([]);
    setSortTable(null)
    setTablePagination({
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
      }
    })
  },[location.pathname])

  const { handleOpenModal, handleCloseModal, openModal, openDelete } = useStore(
    (state) => state
  );

  const openApprove = (id, status,row) => {
    setSelectedUser(row?.name);
    handleOpenModal(id);
    setApproveParams({ id, status });
  
  };
  const openReject = (id, status,row) => {
    setSelectedUser(row?.name);
    handleCloseModal(id);
    setRejectParams({ id, status });
 
  };
  const onCancel = () => {
    handleOpenModal(false);
    handleCloseModal(false);
  };

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);

  };

  const handleTableChange = (page,pageSize) => {
    setTablePagination((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: page,
        pageSize: pageSize,
      },}))
  };

  const header = (
    <div style={{ marginRight: "0" }}>
      <h3 style={{ color: "#39CA6C", fontSize: "1.2rem",width:"100%",alignItems:"center" }}>
        <img src={ApprovedIcon} style={{ marginRight: "0.5rem" }} />
        Approve
      </h3>
    </div>
  );

  let punchType = "";

  if (location.pathname === "/attendancePortal/outdoor-punch") {
    punchType = { ...punchType, punchType: "Outdoor Punch" };
  }
  if (location.pathname === "/attendancePortal/forgot-punch") {
    punchType = { ...punchType, punchType: "Forgot Punch" };
  }

  const tableSize = (params) => (
    {
      limit: params.pagination?.pageSize,
      page: params.pagination?.current,
    }
  );
  
  const getTableData = () => {
    if (
      location.pathname === "/attendancePortal/outdoor-punch" ||
      location.pathname === "/attendancePortal/forgot-punch"
    ) {
      let params = {
        search: search,
        punchType: punchType.punchType,
        ...tableSize(tablePagniation),
      };

      if(sortTable!==null){
        params = {...params, sort:sortTable};
      }
     
      console.log("ssssFilteres",filteredDeptValue,filteredManagerValue)
      if (endDate) {
        params = { ...params, startDate: startDate,endDate: endDate };
      }

      if (statusFilter) {
        params = { ...params, status: statusFilter };
      }
      setLoader(true);
      Api.get(`/attendance-attendance/manualPunchOverview?departmentIds=${JSON.stringify(filteredDeptValue)}&managerIds=${JSON.stringify(filteredManagerValue)}`)
        .params(params)
        .send((res) => {
          if (res !== undefined) {
            setTableData(res?.data);
            setTablePagination({
              ...tablePagniation,
              pagination: {
                ...tablePagniation.pagination,
                total: res?.meta?.totalItems,
                showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
              },
            });
            setLoader(false);
          }
        });
    }
  };

  useEffect(() => {
    getFilterValues(setFilterValues, "departmentAttendance");
    viewClass.updateView();
    getTableData();
    console.log("HERE123",tablePagniation)
  }, [
    search,
    JSON.stringify(tablePagniation),
    statusFilter,
    endDate !== "" && endDate !== undefined && startDate,
    endDate,
    location.pathname,
    filteredDeptValue,
    filteredManagerValue,
    sortTable
  ]);

  const handleApproveYes = () => {
    if (
      location.pathname === "/attendancePortal/outdoor-punch" ||
      location.pathname === "/attendancePortal/forgot-punch"
    ) {
     
      // setLoader(true);
      let params = {
        id: approveParams?.id,
        status: "Approved",
      };
      Api.patch(`/attendance-attendance/manualPunchAction`)
        .params(params)
        .send((res) => {
        
          if (res !== undefined) {
            getTableData()
          }
        });
    }
    handleOpenModal(false);
  };
  const handleRejectYes = () => {
    if (
      location.pathname === "/attendancePortal/outdoor-punch" ||
      location.pathname === "/attendancePortal/forgot-punch"
    ) {
      
      let params = {
        id: rejectParams?.id,
        status: "Rejected",
      };
      Api.patch(`/attendance-attendance/manualPunchAction`)
        .params(params)
        .send((res) => {
          if (res !== undefined) {
            getTableData()
          }
        });
    }
    handleCloseModal(false);
  };

  const handleSearchChange = (newSearch) => {
    setSearch(newSearch);
    setTablePagination((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: 1,
        // pageSize: 10,
      },
    }));
  };

  const handleSort = (key)=>{
    if(key==="Up"){
      setSortTable("AESC")
    }
    else if(key==="Down"){
      setSortTable("DSC")
    }
  }

  return (
    <div style={{marginTop:8,width:width > 700 ? "100%" : "100vw",display:"flex",flexDirection:"column",backgroundColor:"#FFFFFF"}}>
      <div style={{display:"flex",flexDirection:"row",width:"100%",gap:"1rem",justifyContent:"space-between",marginBottom:width>700?"0.4rem":"0.5rem",padding:width>700?"0.2rem 0.7rem":"0 0.7rem"}}>
        {location.pathname === "/attendancePortal/forgot-punch" ? (
          <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",alignItems:"center",}}>
            <h2 style={{ fontSize:width > 700 ? "1.4vw" : 22,}}>Forgot Punch</h2>
            {width > 700 && <span style={{color:"#D3D3D3",fontSize:11,display:"flex",alignItems:"center"}}>
              (Displaying last 7 days Data's)</span>}
          </div>
        ) : location.pathname === "/attendancePortal/outdoor-punch" ? (
          <div style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>
            <h2 style={{ fontSize:width > 700 ? "1.4vw" : 22,}}>Outdoor Punch</h2>
            {width > 700 && <span style={{color:"#D3D3D3",fontSize:11,display:"flex",alignItems:"center"}}>
              (Displaying last 7 days Data's)</span>}
          </div>
        ) : null}
        <div style={{display:"flex",flexDirection:"row",gap:"1rem",marginRight:"0.5rem"}}>
          <Button style={{height:"2.1rem",width:"7vw",backgroundColor:selectedRowKeys.length === 0 ?"#F9F9f9":"#24C850",color:selectedRowKeys.length === 0 ?"#16243280":"#FFFFFF"}}
           disabled={selectedRowKeys.length === 0 ? true :false} 
           onClick={()=>{
              let params = {
                ids: selectedRowKeys,
                status: "Approved",
              };
              Api.patch(`/attendance-attendance/manualPunchActions`)
                 .params(params)
                 .send((res) => {
                  if (res !== undefined) {
                      getTableData()
                      setSelectedRowKeys([])
                  }
              });
          }}>Approve</Button>
          <Button style={{height:"2.1rem",width:"7vw",backgroundColor:selectedRowKeys.length === 0 ?"#F9F9f9":"#FF5745",color:selectedRowKeys.length === 0 ?"#16243280":"#FFFFFF"}}
           disabled={selectedRowKeys.length === 0 ? true :false} 
           onClick={()=>{
             let params = {
              ids: selectedRowKeys,
              status: "Rejected",
            };
            Api.patch(`/attendance-attendance/manualPunchActions`)
              .params(params)
              .send((res) => {
                if (res !== undefined) {
                  getTableData()
                  setSelectedRowKeys([])
                }
              });
          }}>Reject</Button>
        </div>
      </div>

      {width > 700 && 
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",margin:"0.1rem 0.7rem 0.7vh 0.7rem"
            }}
          >
            <SearchCompo 
              value={search}
              onSearch={handleSearchChange} 
              setSearch={handleSearchChange}
              style={{ width:width > 700 ?"21vw":"13.5rem",height:"2.1rem",border:"1px solid #e0e0e0",fontWeight:"100", }}
            >
            </SearchCompo>
            <div style={{display:"flex",flexDirection:"row",gap:"0.9rem"}}>
            <div style={{display:"flex",flexDirection:"row",gap:"0.2rem",alignItems:"center",justifyContent:"center"}}>
            <p>From:</p>
            <DatePicker style={{width:"8vw",height:"2.1rem",}}
            // key={Math.random()}
            // value={startDate?moment(startDate):undefined}
              value={startDate !== "" && startDate !== undefined && startDate !== null ? dayjs(startDate, dateFormat) : null}
              placeholder="Select Date"
              onChange={(date, dateString) => {
                setStartDate(dateString);
                setEndDate("");
                setTablePagination((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                }));
              }}
            />
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"0.2rem",alignItems:"center",justifyContent:"center"}}>
            <p>To:</p>
            <DatePicker style={{width:"8vw",height:"2.1rem"}}
            // key={Math.random()}
            value={endDate !== "" && endDate !== undefined && endDate !== null ? dayjs(endDate, dateFormat) : null}
            disabledDate={(current) => {
              return current && current < moment(startDate);
            }}
              placeholder="Select Date"
              onChange={(date, dateString) => {
                setEndDate(dateString);
                setTablePagination((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                }));
              }}
            />
            </div>
            <Select allowClear placeholder="All"
            key={Math.random()}
            style={{ width:width>700?"8vw":"6.5rem",height:width > 700? "2.1rem":"2.1rem",fontSize:"1vw"}}
            defaultValue={statusFilter}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "Pending",
                label: "Pending",
              },
              {
                value: "Approved",
                label: "Approved",
              },
              {
                value: "Rejected",
                label: "Rejected",
              },
            ]}
            // suffixIcon={PolygonIcon}
            onChange={(value) => {
              setStatusFilter(value);
              setTablePagination((prevState) => ({
                ...prevState,
                pagination: {
                  ...prevState.pagination,
                  current: 1,
                  // pageSize: 10,
                },
              }));
            }}
          ></Select>
          <Select placeholder="Manager" allowClear mode="multiple" 
                    showArrow={true}
                    showSearch={true}
                    maxTagCount={'responsive'}
                    // getPopupContainer={(trigger) => trigger.parentElement}
                    className={classes.SelectScroll}
                    value={filteredManagerValue}
                    style={{
                      width: "11vw",overflow: "scroll",
                      fontSize:"1vw",height:"2.1rem"
                    }}
                    onChange={(e) => {
                      setFilteredManagerValue(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          current: 1,
                          // pageSize: 10,
                        },
                      }));
                    }}
                    disabled={false}
                    // bordered={false}
                    optionFilterProp="children"
                  >
                  {filteredData?.department?.allManager?.map((option, index) => {
                    return(<>{option.name !== undefined &&
                    <Option key={index} value={option.id}>
                    {option.name}
                  </Option>}</>)
                  })}
             </Select>
             <Select placeholder="Department" allowClear mode="multiple"
                    // getPopupContainer={(trigger) => trigger.parentElement}
                    // className="m-t-5"
                    maxTagCount={'responsive'}
                    showArrow={true}
              s     howSearch={true}
                    className={classes.SelectScroll}
                    value={filteredDeptValue}
                    style={{
                      width: "11vw",
                      fontSize:"1vw",
                      overflow: "scroll",height:"2.1rem"
                    }}
                    onChange={(e) => {
                      console.log("ssseEEEE",e)
                      setFilteredDeptValue(e);
                      setTablePagination((prevState) => ({
                        ...prevState,
                        pagination: {
                          ...prevState.pagination,
                          current: 1,
                          // pageSize: 10,
                        },
                      }));
                    }}
                    disabled={false}
                    optionFilterProp="children"
                  >
                  {filteredData?.department?.result?.map((option, index) => {
                    return(<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId}>
                     {option.department}
                  </Option>}</>)
                  })}
             </Select>
          </div>
        </div>
      }
      {width<700 && <div
        style={{
          display: "flex",flexDirection:"row",
          justifyContent: "space-between",
          alignItems: "center", 
          gap:"1rem",
          marginBottom:"0.5rem",padding:"0 0.7rem",width:"100%"
        }}
      >
        <div style={{width:"65%"}}>
        <SearchCompo 
          value={search}
          onSearch={handleSearchChange} 
          setSearch={handleSearchChange}
          tyle={{ width:width > 700 ?"300px":"100%",height:width > 700 ?"2rem":"2.1rem",border:"1px solid #e0e0e0",fontWeight:"100", }}
        >
        </SearchCompo>
        {/* {width > 700 && <span style={{color:"#D3D3D3",alignItems:"center",display:"flex"}}>
          Displaying last 7 days Data's ..</span>} */}
        </div>
        <div style={{ width:"35%" }} >
          <Select allowClear placeholder="All"
            key={Math.random()}
            style={{ width:width>700?"8rem":"100%",height:width > 700? "2.1rem":"2.2rem"}}
            defaultValue={statusFilter}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "Pending",
                label: "Pending",
              },
              {
                value: "Approved",
                label: "Approved",
              },
              {
                value: "Rejected",
                label: "Rejected",
              },
            ]}
            // suffixIcon={PolygonIcon}
            onChange={(value) => {
              setStatusFilter(value);
              setTablePagination((prevState) => ({
                ...prevState,
                pagination: {
                  ...prevState.pagination,
                  current: 1,
                  // pageSize: 10,
                },
              }));
            }}
          ></Select>
        </div>
      </div>}
      {width < 700 && <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              alignItems: "center",padding:"0 0.8rem",marginBottom:"0.5rem"
            }}
          >
            <div style={{fontWeight:600}}>From</div>
           <DatePicker style={{width:"8.3rem",height:width > 700? "2rem":"2.1rem"}}
            // key={Math.random()}
            // defaultValue={startDate?moment(startDate):undefined}
            value={startDate !== "" && startDate !== undefined && startDate !== null ? dayjs(startDate, dateFormat) : null}
            showToday={false}
            placeholder="Select Date"
            onChange={(date, dateString) => {
                setStartDate(dateString);
                setEndDate("");
                setTablePagination((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                }));
              }}
            inputReadOnly={true} 
            />
            <div style={{fontWeight:600}}>To</div>
            <DatePicker style={{width:"8.3rem",height:width > 700? "2rem":"2.1rem"}}
            // key={Math.random()}
            // defaultValue={endDate?moment(endDate):undefined}
            value={endDate !== "" && endDate !== undefined && endDate !== null ? dayjs(endDate, dateFormat) : null}
            disabledDate={(current) => {
              return current && current < moment(startDate);
            }}
            showToday={false}
            placeholder="Select Date"
            onChange={(date, dateString) => {
                setEndDate(dateString);
                setTablePagination((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                }));
              }}
            inputReadOnly={true} 
            />
          </div>
        </div>}
      {/* <Table
      style={{width:"100%"}}
      rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
        onChange={handleTableChange}
        pagination={tablePagniation.pagination}
        loading={loader}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: "58vh" }}
        size="small"
      /> */}
         {width > 700 ?
          <div className={classes.ListContainer}>
            <div className={classes.ListHeader} style={{display:'flex',width:'100%',gap:"0.5rem",fontSize: 15, color: "#16243280", fontWeight: 700,}}>
                          <div style={{minWidth:"2.5rem",justifyContent:'center'}}>
                            <Checkbox
                              indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < tableData.length}
                              checked={selectedRowKeys.length === tableData.length && tableData.length > 0 ? true : false}
                              onClick={(e) => {
                                const selectAll = e.target.checked;
                                const newSelectedRowKeys = selectAll ? tableData.filter((row) => row.status === "Pending").map(row => row.manualPunchID) : [];
                                onSelectChange(newSelectedRowKeys, tableData);
                              }}
                            />
                          </div>
                          <div style={{width:'30%',justifyContent:'flex-start',}}>Associate Name</div>
                          {/* <div style={{width:'18%'}}></div> */}
                          <div style={{width:'18%'}}>Manager</div>
                          <div style={{width:'20%'}}>
                            Application Date 
                            <div style={{display:'flex', flexDirection:'column'}}>
                              <CaretUpOutlined style={{marginBottom:'-4px', color: sortTable==="AESC"? 'black':null}} onClick={()=>{handleSort("Up")}}/> 
                              <CaretDownOutlined style={{color: sortTable==="DSC"? 'black':null}} onClick={()=>{handleSort("Down")}}/>
                            </div>
                          </div>
                          <div style={{width:'20%'}}>Timing</div>
                          <div style={{width:'12%'}}>Duration</div>
                          <div style={{width:'23%'}}>Remarks</div>
                          <div style={{width:'15%'}}>Actions</div>
            </div>
            <div className={classes.ListBodyContainer} style={{height:"61vh", overflowY:'scroll',overflowX:"scroll",width:"100%"}}>
                     
                     {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found</div></div>:
                     tableData?.map((record,id)=>{
                        return(
                          <div style={{width:"100%",}}>
                          <div className={classes.rowContainer} style={{color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",gap:"0.4rem",}}>
                          <div style={{minWidth:"2.5rem",justifyContent:'center'}}>
                            <Checkbox 
                                checked={selectedRowKeys.includes(record?.manualPunchID)} 
                                disabled={record?.status === "Approved" || record?.status === "Rejected"}
                                onClick={(e) => {
                                  const newSelectedRowKeys = e.target.checked
                                    ? [...selectedRowKeys, record?.manualPunchID]
                                    : selectedRowKeys.filter(key => key !== record?.manualPunchID);
                                  const newSelectedRows = tableData.filter(row => newSelectedRowKeys.includes(row.manualPunchID));
                                  onSelectChange(newSelectedRowKeys, newSelectedRows);
                                }} 
                              />
                          </div>

                          <div style={{width:'30%',justifyContent:'flex-start',}}>
                          <div style={{display:'flex',alignItems:'center',gap:'5px',flexDirection:'row',}}>
                              <img style={{ height:"2.3rem",width:"2.3rem",border:"none",borderRadius:"50%",border:"1px dotted #e3e3e3"}} src={UserIcon} />
                              <div style={{display:"flex",flexDirection:"column"}}>
                              <Typography.Text style={{width:"12vw",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                                {record?.name}</Typography.Text>
                              <div style={{fontSize:13}}>{record?.uid}</div>
                              <div>
                          <Typography.Text style={{maxWidth:"12vw",color: "#162432", fontSize: 13, fontWeight: 400, fontStyle: "Lato"}} ellipsis={{tooltip:true}}>
                            {record?.department}</Typography.Text></div>
                              </div>
                          </div>
                          </div>

                          {/* <div style={{width:'18%'}}> */}
                      {/*     <div>
                          <Typography.Text style={{maxWidth:"8vw",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato"}} ellipsis={{tooltip:true}}>
                            {record?.department}</Typography.Text></div> */}
                          {/* </div> */}
                          <div style={{width:'18%'}}>
                          <div><Typography.Text style={{maxWidth:"8vw",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato"}} ellipsis={{tooltip:true}}>
                            {record?.manager}</Typography.Text></div>
                          </div>
                          <div style={{width:'20%'}}>
                          <div>{record?.applicationDate}</div>
                          </div>
                          <div style={{width:'20%'}}>
                          <div>
                          <Typography.Text style={{width:"9vw",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato",}} ellipsis={{tooltip:true}}>
                            {record?.timing}</Typography.Text>
                          </div>
                          </div>
                          <div style={{width:'12%'}} >
                          <div>{record?.duration}</div>
                          </div>
                          <div style={{width:'23%'}}>
                          <div>
                          <Typography.Text style={{width:"10vw",color: "#162432", fontSize: 15, fontWeight: 400, fontStyle: "Lato"}} ellipsis={{tooltip:true}}>
                            {record?.remarks}</Typography.Text>
                          </div>
                          </div>
                          
                          <div style={{width:'15%'}}>
                           {record?.status === "Approved" ? <div style={{color:"#0086FF"}}>{record?.status}</div> 
                           : record?.status === "Rejected" ? <div style={{color:"#0086FF"}}>{record?.status}</div> 
                           : (
                              <Space className={viewClass.hrAssociate?classes.disabled:''}>
                                <a onClick={() => {
                                    openApprove(record?.manualPunchID, record?.status,record);
                                  }}
                                >
                                  <img src={ApproveIcon} style={{ height: "2.2rem" }} />
                                </a>
                                <a onClick={() => {
                                    openReject(record?.manualPunchID, record?.status,record);
                                  }}
                                >
                                  <img
                                    src={DeleteIcon}
                                    style={{ width: "1rem", height: "0.9rem" }}
                                  />
                                </a>
                              </Space>
                            )}
                          </div>
                          </div>
                          </div>
                        )
                      })}
            </div>  
            {/* </div> */}
          </div> 
          :
          <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",padding:"0.5rem 0.9rem"}}>
          {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
             tableData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
             tableData?.map((record,id,index)=>{
                return(
                 <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.8rem 0.7rem"}}>
                 <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between"}}>
                   <div style={{display:"flex",flexDirection:"column",gap:""}}>
                   <Typography.Text style={{width:"40vw",fontSize:"20px",fontWeight:"550"}} ellipsis={{tooltip:true}}>
                     {record?.name ? record?.name :"NA"}
                   </Typography.Text>
                   <p style={{fontSize:"17px",color:"#16243280"}}>{record?.uid ? record?.uid :"NA"}</p>
                   </div>
                   <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                      {record?.status === "Approved" ? 
                            <p style={{color:"#0086FF",fontSize:"20px"}}>updated</p> : record?.status === "Rejected" ? 
                            <p style={{color:"#0086FF",fontSize:"20px"}}>updated</p> : (
                              <Space className={viewClass.hrAssociate?classes.disabled:''}>
                                <a
                                  onClick={() => {
                                    openApprove(record?.manualPunchID, record?.status,record);
                                  }}
                                >
                                  <img src={ApproveIcon} style={{ height: "2.2rem" }} />
                                </a>
                                <a
                                
                                  onClick={() => {
                                    openReject(record?.manualPunchID, record?.status,record);
                                  }}
                                >
                                  <img
                                    src={DeleteIcon}
                                    style={{ width: "1rem", height: "0.9rem" }}
                                  />
                                </a>
                              </Space>
                      )}
                   </div>
                 </div>
 
                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Application Date: </p>
                 <p style={{fontSize:"19px"}}>{record?.applicationDate ? record?.applicationDate :"NA"}</p>
                 </div>
                 
                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Timing: </p>
                 <p style={{fontSize:"19px"}}>{record?.timing ? record?.timing:"NA"}</p>
                 </div>
 
                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Duration: </p>
                 <p style={{fontSize:"19px"}}>{record?.duration ? record?.duration :"NA"}</p>
                 </div>
 
                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",alignItems:"center"}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Remarks: </p>
                 {/* <p style={{fontSize:"19px"}}>{record?.timeStamp ? record?.timeStamp :"NA"}</p> */}
                 <p><Typography.Text style={{width:"60vw",fontSize:20,fontWeight:"650px"}} ellipsis={{tooltip:true}}>
                   {record?.remarks ? record?.remarks :"NA"}</Typography.Text></p>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Punch type: </p>
                 <p style={{fontSize:"19px"}}>{record?.punchType ? record?.punchType :"NA"}</p>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginTop:"0.6rem",}}>
                 <p style={{fontSize:"19px",color:"#16243280"}}>Status: </p>
                 <p style={{fontSize:"19px"}}>{record?.status ? record?.status :"NA"}</p>
                 </div>

              </div>
              )
            })}
          </div>  
          }
          <div>
           <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15,}}
            current={tablePagniation.pagination.current}
            pageSize={tablePagniation.pagination.pageSize}
            total={tablePagniation.pagination.total}
            showSizeChanger={tablePagniation.pagination.showSizeChanger}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={handleTableChange}
            onShowSizeChange={handleTableChange}
          /> 
          </div>

      {location.pathname === "/attendancePortal/forgot-punch" ? (
        <>
          {openModal ? (
            <ModalCompo
              contentStyle={{width:width>700&&"850px",top:"28vh",borderRadius:"8px",margin:width<700&&15}}
              closableRight={true}
              open={openModal}
              onCancel={onCancel}
              header={header}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems:"center",
                  flexDirection: "column",
                  borderTop: "1px solid #F1F1F1",width:"100%"
                }}
              >
                <span style={{ textAlign: "center" }}>
                  <p style={{marginTop: "1rem",fontSize:"16px" }}>
                    Are you sure you want to approve forgot punch for{" "}
                    <span style={{ color: "#0086FF" }}>"{selectedUser}"</span> ?{" "}
                  </p>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3.5rem",
                  }}
                >
                  <ModalPrimaryButton
                    style={{ width: "6rem", height: "2.1rem" }}
                    onSave={handleApproveYes}
                  >
                    Yes
                  </ModalPrimaryButton>
                  <ModalSecondaryButton
                    style={{
                      marginLeft: "5%",
                      backgroundColor: "white",
                      width: "6rem",
                      height: "2.1rem",
                    }}
                    onCancel={onCancel}
                  >
                    No
                  </ModalSecondaryButton>
                </div>
              </div>
            </ModalCompo>
          ) : (
            ""
          )}
          {openDelete ? (
            <ModalCompo
              contentStyle={{width:width>700&&"850px",top:"28vh",borderRadius: "8px",margin:width<700&&15}}
              closableRight={true}
              open={openDelete}
              onCancel={onCancel}
              header={
                <h3 style={{ color: "#FF3434", fontSize: "1.2rem",display:"flex",alignItems:"center" }}>
                  <img src={RejectIcon} style={{ marginRight: "0.5rem" }} />
                  Reject
                </h3>
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems:"center",
                  flexDirection: "column",
                  borderTop: "1px solid #F1F1F1",width:"100%"
                }}
              >
                <span style={{ textAlign: "center" }}>
                  <p style={{marginTop: "1.5rem",fontSize:"16px" }}>
                    Are you sure you want to reject forgot punch for{" "}
                    <text style={{ color: "#0086FF" }}>"{selectedUser}"</text> ?{" "}
                  </p>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",marginTop:"3.5rem"
                  }}
                >
                  <ModalPrimaryButton
                    style={{ width: "6rem", height: "2rem" }}
                    onSave={handleRejectYes}
                  >
                    Yes
                  </ModalPrimaryButton>
                  <ModalSecondaryButton
                    style={{
                      marginLeft: "5%",
                      backgroundColor: "white",
                      width: "6rem",
                      height: "2rem",
                    }}
                    onCancel={onCancel}
                  >
                    No
                  </ModalSecondaryButton>
                </div>
              </div>
            </ModalCompo>
          ) : (
            ""
          )}
        </>
      ) : location.pathname === "/attendancePortal/outdoor-punch" ? (
        <>
          {openModal ? (
            <ModalCompo
              contentStyle={{ width:width>700&&"850px",borderadius:"8px",top:"25vh",margin:width<700&&15 }}
              closableRight={true}
              open={openModal}
              onCancel={onCancel}
              header={header}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderTop: "1px solid #F1F1F1",
                }}
              >
                <span style={{ textAlign: "center" }}>
                  <h4 style={{marginTop: "1.5rem",fontSize:"16px" }}>
                    Are you sure you want to approve outdoor punch for{" "}
                    <span style={{ color: "#0086FF" }}>"{selectedUser}"</span> ?{" "}
                  </h4>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3rem",
                  }}
                >
                  <ModalPrimaryButton
                    style={{ width: "6rem", height: "2.2rem",borderRadius:"5px" }}
                    onSave={handleApproveYes}
                  >
                    Yes
                  </ModalPrimaryButton>
                  <ModalSecondaryButton
                    style={{
                      marginLeft: "5%",
                      backgroundColor: "white",
                      width: "6rem",
                      height: "2.2rem",borderRadius:"5px" 
                    }}
                    onCancel={onCancel}
                  >
                    No
                  </ModalSecondaryButton>
                </div>
              </div>
            </ModalCompo>
          ) : (
            ""
          )}
          {openDelete ? (
            <ModalCompo
              contentStyle={{width:width>700&&"850px",top:"25vh",borderRadius:"10px",margin:width<700&&15 }}
              closableRight={true}
              open={openDelete}
              onCancel={onCancel}
              header={
                <h4 style={{ color: "#FF3434", fontSize: "1.2rem",display:"flex",alignItems:"center" }}>
                  <img src={RejectIcon} style={{ marginRight: "0.5rem" }} />
                  Reject
                </h4>
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderTop: "1px solid #F1F1F1",
                }}
              >
                <span style={{ textAlign: "center" }}>
                  <h4 style={{ marginTop: "1.5rem",fontSize:"16px" }}>
                    Are you sure you want to reject outdoor punch for{" "}
                    <text style={{ color: "#0086FF" }}>"{selectedUser}"</text> ?{" "}
                  </h4>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3rem",
                  }}
                >
                  <ModalPrimaryButton
                    style={{ width: "6rem", height: "2.2rem",borderRadius:"5px" }}
                    onSave={handleRejectYes}
                  >
                    Yes
                  </ModalPrimaryButton>
                  <ModalSecondaryButton
                    style={{
                      marginLeft: "5%",
                      backgroundColor: "white",
                      width: "6rem",
                      height: "2.2rem",borderRadius:"5px"
                    }}
                    onCancel={onCancel}
                  >
                    No
                  </ModalSecondaryButton>
                </div>
              </div>
            </ModalCompo>
          ) : (
            ""
          )}
        </>
      ) : null}
    </div>
  );
};

export default CommonComponent;

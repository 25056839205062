import React, { useState, useEffect } from "react";
import classes from './AllRequest.module.css';
import { Input, Pagination, Select, Spin, Table, Typography,DatePicker } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import select_icon from '../../assets/select_icon.svg';
import reject_icon from '../../assets/reject_icon.svg';
import { useNotificationStore } from "./store";
import ApproveModal from "./ApproveAllModal";
import RejectModal from "./RejectAllModal";
import { request } from "./action";
import TableCustom from "../../components/tableCustom/customTable";
import SearchCompo from "../../design/SearchCompo";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { NoData } from "../Training_essentialDocuments";
import dayjs from "dayjs";
import moment from "moment";
import './custom.css'
import { RiDownloadLine } from "react-icons/ri";

const AllRequest = ({ setCenterModel }) => {

    const dateFormat = "YYYY-MM-DD"

    const { approveAllModal, rejectAllModal, approveAllModalHandler, rejectModalAllHandler, setId, id, user, setSelUser, userSel } = useNotificationStore();
    const [getRequests, setgetRequest] = useState([]);
    const [getRequests2, setgetRequest2] = useState([])
    const [refresh, setrefresh] = useState(true)
    const [message, setmessage] = useState('')
    const [title, settitle] = useState('')
    const [status, setStatus] = useState('')
    const [sort,setSort] = useState(null)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { height, width } = useWindowDimensions();
    console.log("windowDimension", height);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


   const [titleOptions, setTitleOptions] = useState([]);

   


    // const [selectedUser, setselectedUser] = useState()
    let selecteduser = ""

    // const handleSearch = (value) => {
    //     request(status, message, value, (res) => {
    //         setrefresh(false)
    //         setgetRequest(res)
    //     })
    // };
    // const handleMessage = (value) => {
    //     request(status, value, title, (res) => {
    //         setrefresh(false)
    //         setgetRequest(res)
    //     },user?.resourceUser?.id)
    // };

    // useEffect(() => {
    //     setrefresh(true)
    //     request('', title, message, (res) => {
    //         setrefresh(false)
    //         setgetRequest(res)
    //     },user?.resourceUser?.id)
    // }, [])
    const commentComp=(data)=>{
        switch(data?.approvalStatus){
            case "Approved":
                return  data?.comment?   <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel}  ({data?.receiverName
                    }) commented on {data?.commentDate} : </span> 
                '{data?.comment}'
                </Typography.Text>

            </>:  <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel}  ({data?.receiverName
                    }) approved on {data?.commentDate}.</span> 
                
                </Typography.Text>

            </>

            case "Rejected":
                return  data?.comment?   <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel}  ({data?.receiverName
                    }) commented on {data?.commentDate} : </span> 
                '{data?.comment}'
                </Typography.Text>

            </>:  <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel}  ({data?.receiverName
                    }) rejected on {data?.commentDate}.</span> 
                
                </Typography.Text>

            </>

                 case "Cancelled":
                return     data?.comment?      <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw', color: "red" }} >
                    {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentCancelled1} */}
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel} ({data?.receiverName}) commented for cancellation request on {data?.commentDate}: </span> 

                    '{data?.comment}'
                </Typography.Text>

            </>:
                <>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw', color:  "red" }} >
                    {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentCancelled1} */}
                    <span style={{ fontWeight: 600 }}> Approver {data?.approverLevel} ({data?.receiverName}) cancelled the request on {data?.commentDate}. </span> 

                </Typography.Text>
                </>
            default:return  <Typography.Text ellipsis={{ tooltip: true }}> NA </Typography.Text>;
        }
    }
    const getRequest = (download = false) => {
        if (download) {
            setrefresh(true);
            request(status === "All" ? null : status === "Approved" ? "Accepted" : status, message, title, page, pageSize,startDate, endDate, (res) => {
                setrefresh(false);


            }, user?.resourceUser?.id, download, () => { setrefresh(false); },sort)
        } else {
            setrefresh(true);
            let arr = [];
            request(status === "All" ? null : status === "Approved" ? "Accepted" : status, message, title, page, pageSize,startDate, endDate, (res) => {
                setrefresh(false);
                console.log("GET REQUEST", res);
                setgetRequest2(res);
                let temp = res?.allRequests;
              
                temp?.forEach(record => {
                    let obj = {
                        ...record,
                        reasonM: (<>
                            <div style={{ fontSize: 12 }}>{record?.reason}</div>
                        </>),
                        serialNo:
                            <div style={{ paddingLeft: "1rem" }}>
                                <span style={{ fontSize: 12 }}>{record?.serialNo}</span>
                            </div>,
                        title:
                            <div >
                                <Typography.Paragraph ellipsis={{ tooltip: true, }} style={{ width: "7vw" }}>
                                    <span style={{ fontSize: 12 }}>{record?.title}</span>
                                </Typography.Paragraph>
                            </div>,
                        message:
                            <div>
                                <Typography.Paragraph ellipsis={{ tooltip: true, rows: 3 }} style={{ width: "13vw" }}>
                                    <span style={{ fontSize: 12 }}>{record?.message}</span>
                                </Typography.Paragraph>
                                {/* {
                                record?.comment&&<>
                                <Typography.Paragraph ellipsis={{ tooltip: true, rows: 1 }} style={{ width: "13vw" }}>
                                    <span style={{fontSize:12}}><span style={{fontWeight:700}}>Comment</span> : {record?.comment}</span>
                                </Typography.Paragraph>
                                
                                </>
                               } */}
                               {
                                
                               }
                                {
                                    record?.info?.section === "leave" || record?.info?.section === "leaveCancelled"?
                                            record?.approverHistory?.map(ele=>{
                                            return   commentComp(ele);
                                            })
                                            :null
                               
                 
                                    // record?.info?.hasOwnProperty("comments") ?
                                    //     <>
                                    //         {
                                    //          record?.info?.comments?.hasOwnProperty("commentApproved1") &&
                                    //             <>
                                    //                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                    //                     <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented on {record.info?.comments?.commentApproved1Date} : </span> 
                                    //                     {record?.info?.comments?.commentApproved1?`'${record?.info?.comments?.commentApproved1}'`:'NA'}
                                    //                 </Typography.Text>

                                    //             </>
                                    //         }
                                    //         {
                                    //              record?.info?.comments?.hasOwnProperty("commentRejected1") ?
                                    //                 <>
                                    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                    //                         <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented on {record.info?.comments?.commentRejected1Date} : </span> 
                                                        
                                    //                         {record?.info?.comments?.commentRejected1?`'${record?.info?.comments?.commentRejected1}'`:'NA'}
                                    //                         {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentRejected1} */}
                                    //                     </Typography.Text>

                                    //                 </>
                                    //                 :
                                    //                 null
                                    //         }
                                    //         {
                                    //              record?.info?.comments?.hasOwnProperty("commentApproved2")&&
                                    //             <>
                                    //                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                    //                     <span style={{ fontWeight: 600 }}> Approver 2  ({record?.info?.approvers?.approverTwoName}) commented on {record.info?.comments?.commentApproved2Date} : </span> 
                                                      
                                    //                     {record?.info?.comments?.commentApproved2?`'${record?.info?.comments?.commentApproved2}'`:'NA'}

                                    //                     {/* <span style={{ fontWeight: 600  }}> Approver 2 comment:</span> {record?.info?.comments?.commentApproved2} */}
                                    //                 </Typography.Text>

                                    //             </>
                                    //         }
                                    //         {
                                    //             record?.info?.comments?.hasOwnProperty("commentRejected2") ?
                                    //                 <>
                                    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                    //                         <span style={{ fontWeight: 600 }}> Approver 2  ({record?.info?.approvers?.approverTwoName}) commented on {record.info?.comments?.commentRejected2Date} : </span> 
                                                         
                                    //                         {record?.info?.comments?.commentRejected2?`'${record?.info?.comments?.commentRejected2}'`:'NA'}
                                    //                         {/* <span style={{ fontWeight: 600}}> Approver 2 comment:</span> {record?.info?.comments?.commentRejected2} */}
                                    //                     </Typography.Text>

                                    //                 </>
                                    //                 :
                                    //                 null
                                    //         }
                                    //         {
                                    //             record?.info?.comments?.hasOwnProperty("commentCancelled1") ?
                                    //                 <>
                                    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw', color: record?.status === 'Accepted' ? "green" : "red" }} >
                                    //                         {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {record?.info?.comments?.commentCancelled1} */}
                                    //                         <span style={{ fontWeight: 600 }}> Approver 1  ({record?.info?.approvers?.approverOneName}) commented for cancellation request on {record.info?.comments?.commentCancelled1Date}: </span> 

                                    //                         {record?.info?.comments?.commentCancelled1?`'${record?.info?.comments?.commentCancelled1}'`:'NA'}
                                    //                     </Typography.Text>

                                    //                 </>
                                    //                 : null
                                    //         }
                                    //     </>
                                    //     :null
                                        // :
                                        // <>
                                        //     {


                                        //         <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw' }} >
                                        //             Comment: NA
                                        //         </Typography.Text>
                                        //     }
                                        // </>
                                }

                            </div>,
                        /*  commentM:
                         <div>
                             <Typography.Paragraph ellipsis={{ tooltip: true, rows: 3 }} style={{ width: "7vw" }}>
                                 <span style={{fontSize:12}}>{record?.comment}</span>
                             </Typography.Paragraph>
                         </div>, */
                        actions:
                            <div style={{ fontSize: 12 }}>
                                {record?.status === "Accepted" && ((record?.title === "Privilege request" || record?.title === "Sick request" || record?.title === "Casual request"
                                    || record?.title === "Wedding request" || record?.title === "Parental request" || record?.title === "Lop request"||record?.title ===  "Comp_off Leave request") || (record?.info?.section==="leave" ||record?.info?.section==="leaveCancelled")) && record?.old === false && record?.showReject === true ?
                                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "center" }}>
                                        <div
                                            type="text"
                                            style={{
                                                padding: '5px 15px 5px 15px',
                                                background:
                                                    (record?.status === "Accepted" && record?.autoapprove) ? "#26DE81" :
                                                        (record?.status === "Accepted" && !record?.autoapprove) ?
                                                            "rgba(57, 202, 108, 0.40)" :
                                                            (record?.status === "Rejected" && record?.autoapprove) ? "#FC5C65" :
                                                                (record?.status === "Rejected" && !record?.autoapprove) ? "rgba(255, 52, 52, 0.40)" : "rgba(255, 52, 52, 0.40)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "5px",
                                                width: "8rem", height: "2.2rem"
                                            }}
                                        >
                                            <div style={{ color: '#5C5C5C', }}>
                                                {(record?.status === "Accepted" && record?.autoapprove) ? "Auto-Approved" :
                                                    (record?.status === "Accepted" && !record?.autoapprove) ? "Approved" :
                                                        (record?.status === "Rejected" && record?.autoapprove) ? "Auto-Rejected" :
                                                            (record?.status === "Rejected" && !record?.autoapprove) ? "Rejected" :
                                                                record?.status === "Cancelled" ? "Cancelled" : ""




                                                }
                                                {/* {record?.status==="Accepted"?'Approved':record?.status==="Cancelled"?"Cancelled":'Rejected'} */}
                                            </div>
                                        </div>
                                        <img src={reject_icon} style={{ cursor: "pointer", width: "2rem", height: "2rem" }} onClick={() => {
                                            selecteduser = record
                                            setSelUser(record)

                                            setId(record?.notificationId)
                                            rejectModalAllHandler(true);

                                        }} />
                                    </div>
                                    : record?.status !== 'Pending' ?
                                        <div
                                            type="text"
                                            style={{
                                                padding: '7px 15px 5px 15px',
                                                background: (record?.status === "Accepted" && record?.autoapprove) ? "#26DE81" :
                                                    (record?.status === "Accepted" && !record?.autoapprove) ?
                                                        "rgba(57, 202, 108, 0.40)" :
                                                        (record?.status === "Rejected" && record?.autoapprove) ? "#FC5C65" :
                                                            (record?.status === "Rejected" && !record?.autoapprove) ? "rgba(255, 52, 52, 0.40)" : "rgba(255, 52, 52, 0.40)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "5px",
                                                width: "8rem", height: "2.2rem"
                                            }}
                                        >
                                            <div style={{ color: '#5C5C5C', }}>
                                                {/* {record?.status==="Accepted"?'Approved':record?.status==="Cancelled"?"Cancelled":'Rejected'} */}
                                                {(record?.status === "Accepted" && record?.autoapprove) ? "Auto-Approved" :
                                                    (record?.status === "Accepted" && !record?.autoapprove) ? "Approved" :
                                                        (record?.status === "Rejected" && record?.autoapprove) ? "Auto-Rejected" :
                                                            (record?.status === "Rejected" && !record?.autoapprove) ? "Rejected" :
                                                                record?.status === "Cancelled" ? "Cancelled" : ""




                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className={classes.actions}>
                                            <img src={select_icon} onClick={() => {
                                                selecteduser = record
                                                setSelUser(record)
                                                setId(record?.notificationId)
                                                approveAllModalHandler(true);
                                                console.log(selecteduser, "000000000000000000000000000000000");
                                            }} />
                                            <img src={reject_icon} onClick={() => {
                                                selecteduser = record
                                                setSelUser(record)

                                                setId(record?.notificationId)
                                                rejectModalAllHandler(true);
                                                console.log(selecteduser, "000000000000000000000000000000000");
                                            }} />
                                        </div>
                                }

                            </div>
                    };
                    arr.push(obj);
                });
                setgetRequest({ ...res, ['allRequests']: arr })
                const tempTitleOptions = [];
                for(let i=0;i<res?.title?.length;i++){
                    tempTitleOptions.push({
                        label: res?.title?.[i],
                        value: res?.title?.[i],
                      });
                }
                setTitleOptions(tempTitleOptions);
            }, user?.resourceUser?.id, false,null,sort)
        }
    }

    useEffect(() => {
        if (user?.resourceUser?.id) {
            
            getRequest();
        }
    }, [status, message, title, user?.resourceUser?.id, page, pageSize,startDate,endDate,sort])


    const Option = Select.Option;

    const options = ["All", "Pending", 'Approved', 'Rejected', 'Cancelled']

    const tableColumn = {
        0: {
            cols: "S.No",
            width: "5%",
            dataKey: "serialNo",
            onclick: () => { }
        },
        1: {
            cols: "Title",
            width: "15%",
            dataKey: "title",
            onclick: () => { }
        },
        2: {
            cols: "Message",
            width: "35%",
            dataKey: "message",
            onclick: () => { }
        },
        3: {
            cols: "Reason",
            width: "20%",
            dataKey: "reason",
            onclick: () => { }
        },
        // 4: {
        //     cols:  (
        //         <div style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center' }}>
        //             <div>Date</div> 
        //             <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        //                 <CaretUpOutlined style={{marginBottom:'-4px', cursor:'pointer', color: sort === 'ASC' ? 'black' : 'gray' }} onClick={()=>{setSort('ASC')}}/>
        //                 <CaretDownOutlined style={{cursor:'pointer', color: sort === 'DESC' ? 'black' : 'gray'}} onClick={()=>{setSort('DESC')}}/>
        //             </div>
        //         </div>
        //     ),
        //     width: "25%",
        //     dataKey: "date",
        //     onclick: () => { }
        // },

        4: {
            cols: "Actions",
            width: "25%",
            dataKey: "actions",
            onclick: () => { }
        },
    }



    const data = [
        {
            "id": 4,
            "yearMonth": "2023-06",
            "requestedBy": "1",
            "title": "TITLIIIIII",
            "message": "MESESSSSSSSSSSSSSJABHKJDFHS",
            "info": "\"{\\n  request_id: 1,\\n  section: 'manual'\\n}\"",
            "type": "Notification",
            "status": "Pending",
            "seenStatus": false,
            "created_at": "2023-06-27T10:47:48.907Z",
            "updated_at": "2023-06-27T10:47:48.907Z"
        },
        {
            "id": 4,
            "yearMonth": "2023-06",
            "requestedBy": "1",
            "title": "TITLIIIIII",
            "message": "MESESSSSSSSSSSSSSJABHKJDFHS",
            "info": "\"{\\n  request_id: 1,\\n  section: 'manual'\\n}\"",
            "type": "Notification",
            "status": "Pending",
            "seenStatus": false,
            "created_at": "2023-06-27T10:47:48.907Z",
            "updated_at": "2023-06-27T10:47:48.907Z"
        },
        {
            "id": 4,
            "yearMonth": "2023-06",
            "requestedBy": "1",
            "title": "TITLIIIIII",
            "message": "MESESSSSSSSSSSSSSJABHKJDFHS",
            "info": "\"{\\n  request_id: 1,\\n  section: 'manual'\\n}\"",
            "type": "Notification",
            "status": "Pending",
            "seenStatus": false,
            "created_at": "2023-06-27T10:47:48.907Z",
            "updated_at": "2023-06-27T10:47:48.907Z"
        }
    ]

    const handleSearchChange = (newSearch) => {

        settitle(newSearch);
        setPage(1)
    };
    const changeHandler=(value)=>{
        setQuery(value);
    }
    const [query, setQuery] = useState('');
    useEffect(()=>{
        const timeOutId = setTimeout(()=>{
            handleSearchChange(query);
            console.log('filter search')
        },1000)
        return ()=> clearTimeout(timeOutId)
    },[query])


    const handleSearchChangeDD = (newSearch) => {
        //console.log("search trigger")
        setmessage(newSearch);
        setPage(1)
    };

    return (
        <div className={classes.request}>

            <div className={classes.header}>

                <svg style={{ cursor: "pointer", }} onClick={() => {
                    setCenterModel(false)
                }} xmlns="http://www.w3.org/2000/svg" width="15" height="22" viewBox="0 0 15 26" fill="none">
                    <path d="M13 2L2.35355 12.6464C2.15829 12.8417 2.15829 13.1583 2.35355 13.3536L13 24" stroke="#162432" stroke-width="3" stroke-linecap="round" />
                </svg>
                <h3 style={{ color: '#000', fontSize: "1.4rem", fontWeight: "600" }}> All Requests</h3>
                {width < 800 && <Select
                    style={{ width: "40%", marginLeft: "auto" }}
                    onSelect={(e) => {
                        setStatus(e)
                        setPage(1)
                        // setrefresh(true)

                        // request(e=="All"?null:e="Approved"?"Accepted":e, message, title, (res) => {
                        //     setrefresh(false)
                        //     setgetRequest(res)
                        // },user?.resourceUser?.id)
                    }

                    }
                    placeholder="Select" defaultValue="All" size="large">
                    {options.map((item, index) => {
                        return (
                            <Option value={item} key={index}>{item}</Option>
                        )
                    })}

                </Select>}
            </div>
            <div style={{ display: "flex", color: "#FFB783", alignItems: "center", justifyContent: "flex-end", gap: 5, marginTop: 10 }}>
                <RiDownloadLine />
                <div onClick={() => {getRequest(true)}}  style={{ cursor: "pointer", }}>Download history</div>
            </div>
            <section className={classes.requestTable}>
                <div className={classes.searchDiv}>
                    <div className={classes.searchfield}>
                        {/*<SearchCompo
                            onSearch={handleSearchChange}
                            setSearch={handleSearchChange} placeholder="Search by title" type="text" style={{ height: "2.5rem", border: "1px solid #dfdfdf" }} />*/}
                         
                         <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                              }}
                            placeholder="Search By Title"
                            defaultValue={[]}
                            onChange={changeHandler}
                            options={titleOptions}
                            
                            className="classes.titlesearch"
                        />
                
                        <SearchCompo
                            setSearch={handleSearchChangeDD}
                            onSearch={handleSearchChangeDD} type="text" placeholder="Search by message" style={{ height: "2.5rem", border: "1px solid #dfdfdf" , width: window.innerWidth > 800 ? '100%' : '100%'}} />
                        {/* <Input.Search allowClear={true}  type="text" placeholder="Search by title" size="large" onChange={(e) => { settitle(e.target.value) }} />
                        <Input.Search allowClear={true} type="text" placeholder="Search by message" size="large" onChange={(e) => { setmessage(e.target.value) }} /> */}
                        <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem", alignItems: "center", justifyContent: "center" }}>
                                <span>From:</span>
                                <DatePicker style={{ width: "8vw", height: "2.1rem", }}
                                    value={startDate !== "" && startDate !== undefined && startDate !== null ? dayjs(startDate, dateFormat) : null}
                                    placeholder="Select Date"
                                    onChange={(date, dateString) => {
                                        setStartDate(dateString);
                                        setEndDate("");
                                        setPage(1)
                                    }}
                                />
                            </div>{"-"}
                            <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem", alignItems: "center", justifyContent: "center" }}>
                                <span>To:</span>
                                <DatePicker style={{ width: "8vw", height: "2.1rem" }}
                                    value={endDate !== "" && endDate !== undefined && endDate !== null ? dayjs(endDate, dateFormat) : null}
                                    disabledDate={(current) => {
                                        return current && current < moment(startDate);
                                    }}
                                    placeholder="Select Date"
                                    onChange={(date, dateString) => {
                                        setEndDate(dateString);
                                        setPage(1)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {width > 800 && <Select
                        onSelect={(e) => {
                            setStatus(e)
                            setPage(1)
                            // setrefresh(true)

                            // request(e=="All"?null:e="Approved"?"Accepted":e, message, title, (res) => {
                            //     setrefresh(false)
                            //     setgetRequest(res)
                            // },user?.resourceUser?.id)
                        }

                        }
                        placeholder="Select" defaultValue="All" size="large">
                        {options.map((item, index) => {
                            return (
                                <Option value={item} key={index}>{item}</Option>
                            )
                        })}

                    </Select>}
                </div>

                <div style={{overflowX:"auto",height:'65vh'}}>
                    {/* <Table
                        loading={refresh}
                        scroll={{y:300}}
                        columns={columns} dataSource={getRequests?.allRequests} 
                        pagination={{
                            pageSize:parseInt(pageSize),
                            pageSizeOptions:[10,20,50,100,150,200],
                            showSizeChanger:true,
                            current:parseInt(page),
                             total:parseInt(getRequests?.meta?.total),
                            onChange:(page,pageSize)=>{
                                setPage(page);
                                setPageSize(pageSize);
                            }
                        }}
                        /> */}
                    {width > 800 ?
                        <TableCustom columns={tableColumn} dataSource={getRequests?.allRequests} loading={refresh} pagination={
                            {
                                total: parseInt(getRequests?.meta?.total),
                                current: parseInt(page),
                                showSizeChanger: true,
                                pageSizeOptions: [10, 20, 30, 50, 100],
                                pageSize: parseInt(pageSize),
                                onChange: (page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                },
                            }}
                            styles={{ height: height < 648 ? "41vh" : height < 738 ? "45vh" : "50vh"}}
                        /> :
                        <div className={classes.mobileDiv}>
                            {console.log("getRequests2", getRequests2)}
                            {refresh ? <Spin style={{ display: "flex", width: "100%", height: "50vh", alignItems: "center", justifyContent: "center" }}></Spin> : getRequests?.allRequests?.length > 0 ?
                                getRequests2?.allRequests?.map((item, index) => {
                                    let targetDate = null;

                                    if (item?.status === "Accepted" && (item?.title === "Privilege request" || item?.title === "Sick request"
                                        || item?.title === "Casual request" || item?.title === "Wedding request" || item?.title === "Parental request" || item?.title === "Lop request")
                                    ) {
                                        if (item.info.startDate) {
                                            targetDate = moment(item.info.startDate).format("YYYY-MM-DD");
                                        } else if (item.info.date) {
                                            targetDate = moment(item.info.date).format("YYYY-MM-DD");
                                        } else if (item.info.manualPunchDate) {
                                            targetDate = moment(item.info.manualPunchDate).format("YYYY-MM-DD");
                                        }
                                    }
                                    const today = moment();
                                    const differenceInDays = targetDate !== null ? moment(targetDate).diff(today, 'days') : null;
                                    const isWithinLast3Days = targetDate !== null && differenceInDays !== null && differenceInDays > 2 && moment(targetDate).isSameOrAfter(today, 'day');
                                    console.log("sssssDate", isWithinLast3Days, differenceInDays, targetDate);
                                    return (
                                        <div className={classes.mobCard} key={index}>
                                            <div>
                                                <div>Title:</div>
                                                <div>{item.title}</div>
                                            </div>
                                            <div>
                                                <div>Message:</div>
                                                <div>{item?.message}</div>
                                            </div>
                                            <div>
                                                {item?.approverHistory?.length>0 && (item?.info.section ==="leave" ||item?.info.section === "leaveCancelled")?
                                                <>
                                                <div>Comment:</div>
                                                <div>
                                                    {
                                                        // item?.approverHistory?.map(ele=>{
                                                        //     return   commentComp(ele);
                                                        //     })

                                                        
                                          item?.approverHistory?.length>0?  
                                          item?.approverHistory?.map(ele=>{
                                           return   commentComp(ele);
                                           })
                                           :
                                           <Typography.Text ellipsis={{ tooltip: true }}> NA </Typography.Text>
                              
                                                        // item?.info?.hasOwnProperty("comments") ?
                                                        //     <>
                                                        //         {
                                                        //             item?.info?.comments?.hasOwnProperty("commentApproved1") &&
                                                        //             <>
                                                        //                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                                        //                     <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentApproved1Date} : </span>
                                                        //                     {item?.info?.comments?.commentApproved1?`'${item?.info?.comments?.commentApproved1}'`:'NA'}
                                                        //                 </Typography.Text>

                                                        //             </>
                                                        //         }
                                                        //         {
                                                        //             item?.info?.comments?.hasOwnProperty("commentRejected1") ?
                                                        //                 <>
                                                        //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                                        //                         <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentRejected1Date} : </span> 
                                                        //                         {item?.info?.comments?.commentRejected1?`'${item?.info?.comments?.commentRejected1}'`:'NA'}
                                                        //                         {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {item?.info?.comments?.commentRejected1} */}
                                                        //                     </Typography.Text>

                                                        //                 </>
                                                        //                 :
                                                        //                 null
                                                        //         }
                                                        //         {
                                                        //             item?.info?.comments?.hasOwnProperty("commentApproved2") &&
                                                        //             <>
                                                        //                 <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "green" }} >
                                                        //                     <span style={{ fontWeight: 600 }}> Approver 2  ({item?.info?.approvers?.approverTwoName}) commented on {item.info?.comments?.commentApproved2Date} : </span> 
                                                        //                     {item?.info?.comments?.commentApproved2?`'${item?.info?.comments?.commentApproved2}'`:'NA'}
                                                        //                     {/* <span style={{ fontWeight: 600  }}> Approver 2 comment:</span> {item?.info?.comments?.commentApproved2} */}
                                                        //                 </Typography.Text>

                                                        //             </>
                                                        //         }
                                                        //         {
                                                        //             item?.info?.comments?.hasOwnProperty("commentRejected2") ?
                                                        //                 <>
                                                        //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '20vw', color: "red" }} >
                                                        //                         <span style={{ fontWeight: 600 }}> Approver 2  ({item?.info?.approvers?.approverTwoName}) commented on {item.info?.comments?.commentRejected2Date} : </span> 
                                                        //                         {item?.info?.comments?.commentRejected2?`'${item?.info?.comments?.commentRejected2}'`:'NA'}
                                                        //                         {/* <span style={{ fontWeight: 600}}> Approver 2 comment:</span> {item?.info?.comments?.commentRejected2} */}
                                                        //                     </Typography.Text>

                                                        //                 </>
                                                        //                 :
                                                        //                 null
                                                        //         }
                                                        //         {
                                                        //             item?.info?.comments?.hasOwnProperty("commentCancelled1") ?
                                                        //                 <>
                                                        //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw', color: item?.status === 'Accepted' ? "green" : "red" }} >
                                                        //                         {/* <span style={{ fontWeight: 600 }}> Approver 1 comment:</span> {item?.info?.comments?.commentCancelled1} */}
                                                        //                         <span style={{ fontWeight: 600 }}> Approver 1  ({item?.info?.approvers?.approverOneName}) commented on {item.info?.comments?.commentApproved1Date} : </span>
                                                        //                         {item?.info?.comments?.commentCancelled1?`'${item?.info?.comments?.commentCancelled1}'`:'NA'}

                                                        //                     </Typography.Text>

                                                        //                 </>
                                                        //                 : null
                                                        //         }
                                                        //     </>:null
                                                            // :
                                                            // <>
                                                            //     {


                                                            //         <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '13vw' }} >
                                                            //             NA
                                                            //         </Typography.Text>
                                                            //     }
                                                            // </>
                                                    }
                                                </div>
                                                </>
                                                :null} 
                                            </div>
                                            <div>
                                                <div>Actions:</div>
                                                <div>
                                                    {item?.status === "Accepted" && (item?.title === "Privilege request" || item?.title === "Sick request" || item?.title === "Casual request"
                                                        || item?.title === "Wedding request" || item?.title === "Parental request" || item?.title === "Lop request") && item?.old === false && item?.showReject === true ?
                                                        <div
                                                            type="text"
                                                            style={{
                                                                padding: '5px 15px 5px 15px',
                                                                background: (item?.status === "Accepted" && item?.autoapprove) ? "#26DE81" :
                                                                    (item?.status === "Accepted" && !item?.autoapprove) ?
                                                                        "rgba(57, 202, 108, 0.40)" :
                                                                        (item?.status === "Rejected" && item?.autoapprove) ? "#FC5C65" :
                                                                            (item?.status === "Rejected" && !item?.autoapprove) ? "rgba(255, 52, 52, 0.40)" : "rgba(255, 52, 52, 0.40)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                borderRadius: "5px",
                                                                width: "9rem",
                                                            }}
                                                        >
                                                            <div style={{ color: '#5C5C5C', }}>
                                                                {/* {item?.status==="Accepted"?'Approved':item?.status==="Cancelled"?"Cancelled":'Rejected'} */}
                                                                {(item?.status === "Accepted" && item?.autoapprove) ? "Auto-Approved" :
                                                                    (item?.status === "Accepted" && !item?.autoapprove) ? "Approved" :
                                                                        (item?.status === "Rejected" && item?.autoapprove) ? "Auto-Rejected" :
                                                                            (item?.status === "Rejected" && !item?.autoapprove) ? "Rejected" :
                                                                                item?.status === "Cancelled" ? "Cancelled" : ""




                                                                }
                                                            </div>
                                                        </div>
                                                        : item?.status !== 'Pending' ?
                                                            <div
                                                                type="text"
                                                                style={{
                                                                    padding: '5px 15px 5px 15px',
                                                                    background: (item?.status === "Accepted" && item?.autoapprove) ? "#26DE81" :
                                                                        (item?.status === "Accepted" && !item?.autoapprove) ?
                                                                            "rgba(57, 202, 108, 0.40)" :
                                                                            (item?.status === "Rejected" && item?.autoapprove) ? "#FC5C65" :
                                                                                (item?.status === "Rejected" && !item?.autoapprove) ? "rgba(255, 52, 52, 0.40)" : "rgba(255, 52, 52, 0.40)",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    borderRadius: "5px",
                                                                    width: "9rem",
                                                                }}
                                                            >
                                                                <div style={{ color: '#5C5C5C', }}>
                                                                    {/* {item?.status==="Accepted"?'Approved':item?.status==="Cancelled"?"Cancelled":'Rejected'} */}
                                                                    {(item?.status === "Accepted" && item?.autoapprove) ? "Auto-Approved" :
                                                                        (item?.status === "Accepted" && !item?.autoapprove) ? "Approved" :
                                                                            (item?.status === "Rejected" && item?.autoapprove) ? "Auto-Rejected" :
                                                                                (item?.status === "Rejected" && !item?.autoapprove) ? "Rejected" :
                                                                                    item?.status === "Cancelled" ? "Cancelled" : ""




                                                                    }
                                                                </div>
                                                            </div> :

                                                            <div className={classes.actions}>
                                                                <img src={select_icon} onClick={() => {
                                                                    selecteduser = item
                                                                    setSelUser(item)
                                                                    setId(item?.notificationId)
                                                                    approveAllModalHandler(true);
                                                                    console.log(selecteduser, "000000000000000000000000000000000");


                                                                }} />
                                                                <img src={reject_icon} onClick={() => {
                                                                    selecteduser = item
                                                                    setSelUser(item)

                                                                    setId(item?.notificationId)
                                                                    rejectModalAllHandler(true);
                                                                    console.log(selecteduser, "000000000000000000000000000000000");
                                                                }} />
                                                            </div>
                                                    }

                                                </div>
                                            </div>


                                        </div>
                                    )
                                }) :
                                <NoData />
                            }

                            <Pagination position="bottomRight" size="middle" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}
                                current={parseInt(page)} showSizeChanger={true} total={parseInt(getRequests?.meta?.total)} pageSize={parseInt(pageSize)}
                                onChange={(page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                }}
                                onShowSizeChange={(page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                }}
                                pageSizeOptions={[10, 20, 30, 50, 100, 200]}
                            />

                        </div>
                    }
                </div>
                {approveAllModal && <ApproveModal refresh={getRequest.bind(this)} />}
                {rejectAllModal && <RejectModal refresh={getRequest.bind(this)} />}

            </section>
        </div>
    )
}
export default AllRequest;